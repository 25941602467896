import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Title from './Title';
import { personalData } from "../config/personalData.js";
import profilePicture from '../config/profilepicture.jpg';
import MuiMarkdown from 'mui-markdown';
import argentiere_image from '../image/argentiere-north-face.jpeg';
import CardMedia from '@mui/material/CardMedia';

function AvatarCard() {
  return (
   <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: '100vh' }}
   >
     <Card>
       <CardContent>
         <Avatar alt="Lucien" src={profilePicture} sx={{ width: 300, height: 300 }}/>
       </CardContent>
         <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
         >
           <Typography variant='h6'>
             Kin Ho (Lucien) Lo
           </Typography>
           <Typography variant='subtitle2'>
             Data scientist @ Booking.com
           </Typography>
           <Typography variant='subtitle2'>
             Particle physicist @ CERN
           </Typography>
           <Typography variant='subtitle2'>
             Kaggler (Competition Expert)
           </Typography>
           <div>
           <GitHubIcon 
             sx={{mb: 0.5}}
             onClick={() => {window.open('https://github.com/lucien1011','_blank')}}
           />
           <LinkedInIcon 
             sx={{mb: 0.5}}
             onClick={() => {window.open('https://www.linkedin.com/in/kin-ho-lucien-lo/','_blank')}}
           />
           </div>
        </Grid>
     </Card>
   </Grid>
  );
}

function Biography(props){
  const { descriptions, profilePicture } = props;
  return (
    <Grid container columnSpacing={{ xs: 2 }} >
      <Grid item xs={12} sm={4} >
        {<AvatarCard/>}
      </Grid>
      <Grid item xs={12} sm={8} >
        <Card>
          <CardContent>
              <Typography sx={{ fontSize: 35 }} align='center'>
               👋 Hello! Nice to meet you!
              </Typography>
              <Typography sx={{ fontSize: 20 }} align='center'>
                I am currently a data scientist at Booking.com. I am previously a particle physicist at CERN.
              </Typography>
              <Typography sx={{ fontSize: 25 }}>
                💻 I enjoy solving problems with data and quantitative methods.
              </Typography>
              <Typography sx={{ fontSize: 25 }}>
                ⛰️ I enjoy outdoor adventures. I especially enjoy going into the mountains.
              </Typography>
              <Typography sx={{ fontSize: 25 }}>
                🖊️ I write about my work and adventures here. Send me a message to connect if you find them interesting!
              </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default function MainPage() {

  const {
    name,
    descriptions,
    email,
    image,
    imageText,
    title,
  } = personalData;

  return (
    <main>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${image})`,
        }}
      >
        {<img style={{ display: 'none' }} src={image} alt={imageText} />}
        <Box
          sx={{
            position: 'relative',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                About me
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      
      <Biography descriptions={descriptions} profilePicture={profilePicture} />
    </main>
  )
}
// }
