import BlockPage from './BlockPage'

import mountain_001 from '../image/mountain-001.jpeg';
import mountain_002 from '../image/mountain-002.jpeg';
import mountain_003 from '../image/mountain-003.jpeg';
import mountain_004 from '../image/mountain-004.jpeg';

const title = {
  title: 'Data Science Paper Notes',
  description: "",
  image: mountain_002,
};

const clvMarkovChainCard = {
  title: 'Beyond Customer Lifetime Valuation: Measuring the Value of Acquisition and Retention for Subscription Services',
  description: "A summary of an interesting paper using Markov Chain to estimate the value of acquisition and retention.",
  url: "/ds-paper-notes-clv-markov-chain",
  image: mountain_003,
};

const cards = [
    clvMarkovChainCard,
];

export default function DSPage() {
  return <BlockPage title={title} cards={cards} />;
}
