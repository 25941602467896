import BlockPage from './BlockPage'

import star_001 from '../image/star-001.jpeg';

const title = {
  title: 'Kaggle',
  description: "",
  image: star_001,
};

const cards = [
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
