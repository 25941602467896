import Title from './Title';
import FeaturedPost from './FeaturedPost';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import mountain_004 from '../image/mountain-004.jpeg';

const titleInfo = {
  title: 'Alpinisme',
  description: "",
  image: mountain_004,
};

const featuredPosts = [
];

export default function AlpinsimePage() {
  return (
    <Container>
      <main>
        <Title post={titleInfo} />
        <Grid container spacing={4}>
          {featuredPosts.map((post) => (
            <FeaturedPost key={post.title} post={post} />
          ))}
        </Grid>
      </main>
    </Container>
  )
}
