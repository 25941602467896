import Title from './Title';
import FeaturedPost from './FeaturedPost';
import FeaturedCard from './FeaturedCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Post200412 from './ML/blog-post-2020-04-12-fast-ml';
import Post200916 from './ML/blog-post-2020-09-20-climbing-varautoencoder';

import mountain001_image from '../image/mountain-001.jpeg';
import mountain002_image from '../image/mountain-002.jpeg';

const titleInfo = {
  title: 'My Machine Learning publication',
  description: "",
  image: 'https://source.unsplash.com/random?wallpapers',
};

const featuredPosts = [];

export default function MyMLPaperPage() {
  return (
    <main>
      <Title post={titleInfo} />

      <Grid container spacing={4}>
        <Grid item>
          <Card sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
            image={mountain001_image}
          />
            <CardContent>
              <Post200916 />
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
            image={mountain002_image}
          />
            <CardContent>
              <Post200412 />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </main>
  )
}
