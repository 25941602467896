import * as React from "react";
import ReactMarkdown from "markdown-to-jsx";
import Title from '../Title';

import post from "./blog-post-2024-03-11-t-test.md";

import mountain_002 from '../../image/mountain-002.jpeg';

const titleInfo = {
  title: 'Data science statistics notes',
  description: "",
  image: mountain_002,
};


export default function BasicButtons() {
  let [readable, setReadable] = React.useState({ md: "" });

  React.useEffect(() => {
    fetch(post)
      .then((res) => res.text())
      .then((md) => {
        setReadable({ md });
      });
  }, []);

  return (
    <div className="post">
      <Title post={titleInfo} />
      <ReactMarkdown children={readable.md} />
    </div>
  );
}

