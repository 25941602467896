import BlockPage from './BlockPage'

import forest_001 from '../image/forest-001.jpeg'
import norway_001 from '../image/norway-001.jpeg';
import universe_001 from '../image/universe-001.jpeg';
import universe_002 from '../image/universe-002.jpeg';
import mountain_001 from '../image/mountain-001.jpeg';
import mountain_002 from '../image/mountain-002.jpeg';

const title = {
  title: 'Machine Learning',
  description: "",
  image: forest_001,
};

const myPaperCard = {
  title: 'Publications on Machine Learning',
  description: 'A list of my publications on Machine Learning',
  url: '/ml-my-ml-paper',
  image: universe_002,
};

const mbCard = {
  title: 'Moonboard problem generator',
  description: "An end-to-end Moonboard problem generator built by me, powered by machine learning models. This app is deployed with streamlit and AWS Lambda.",
  url: "/ml-mb-problem-generator",
  image: norway_001,
};

const gbmCard = {
  title: 'Boosting algorithms in machine learning',
  description: "My practical guides on boosting algorithms in machine learning",
  url: "/ml-boosting",
  image: universe_001,
};

const mabCard = {
  title: 'Multi-arm bandit algorithms',
  description: "My practical guides on multi-arm bandit problems",
  url: "/ml-intro-mab",
  image: mountain_002,
};

const cards = [
  myPaperCard,
  mbCard,
  gbmCard,
  //mabCard,
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
