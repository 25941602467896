import * as React from "react";

import MarkdownPost from '../MarkdownPost.js';

import norway_001 from '../../image/norway-001.jpeg';
import post from "./blog-post-2024-03-11-boosting-concept.md";

const title = {
  title: 'Concepts of boosting in machine learning',
  description: "Deep dive into concepts in boosting algorithms",
  image: norway_001,
};

export default function Post() {
  let [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    fetch(post)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return <MarkdownPost title={title} markdown={markdown} />; 
}

