import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from './Header';
import Footer from './Footer';

// Main tab
import MainPage from './MainPage.js';
import DSPage from './DSPage.js';
import MLPage from './MLPage.js';
import AIPage from './AIPage.js';
import KagglePage from './KagglePage.js';
import PortfolioPage from './PortfolioPage.js';
import PhysicsPage from './PhysicsPage.js';
import AlpinismePage from './AlpinismePage.js';
import RockClimbingPage from './RockClimbingPage.js';

// Machine learning main topics
import MyMLPaperPage from './MyMLPaperPage.js';
import MLBoostingNotes from './MLBoostingNotes.js';

// Machine learning post
import Post_ml_mbgenerator from './ML/blog-post-2024-03-07-mb-generator.js';
import Post_ml_boosting_concept from './ML/blog-post-2024-03-11-boosting-concept.js';
import Post_ml_boosting_xgboost from './ML/blog-post-2024-03-17-boosting-xgboost.js';

// Data science main topics
import DSPaperNotes from './DSPaperNotes.js';
import DSStatNotes from './DSStatNotes.js';

// Data Science post
import Post_ds_231124_clvmc from './DataScience/blog-post-2023-11-24-clv-markov-chain.js';
import Post_ds_240311_ttest from './DataScience/blog-post-2024-03-11-t-test.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const sections = [
  { title: 'About me', url: '/' },
  { title: 'Data Science', url: 'ds' },
  { title: 'Machine Learning', url: 'ml' },
  //{ title: 'AI', url: 'ai' },
  { title: 'Kaggle', url: 'kaggle' },
  { title: 'Portfolio', url: 'portfolio' },
  { title: 'Physics', url: 'physics' },
  //{ title: 'Alpinisme', url: 'alpinisme' },
  //{ title: 'Rock climbing', url: 'rockclimbing' },
];

const defaultTheme = createTheme();

export default function Blog() {
  return (
      <Container>
        <Router>
          <Header title="Kin Ho (Lucien) Lo" sections={sections} />
          <Routes>
            <Route exact path='/' element={<Container><MainPage/></Container>} />
            <Route path='ds' element={<DSPage/>} />
            <Route path='ml' element={<MLPage/>} />
            //<Route path='ai' element={<AIPage/>} />
            <Route path='kaggle' element={<KagglePage/>} />
            <Route path='portfolio' element={<PortfolioPage/>} />
            //<Route path='physics' element={<PhysicsPage/>} />
            //<Route path='alpinisme' element={<AlpinismePage/>} />
            //<Route path='rockclimbing' element={<RockClimbingPage/>} />
            
            <Route path='ml-boosting' element={<MLBoostingNotes/>} />
            <Route path='ml-my-ml-paper' element={<MyMLPaperPage/>} />
            <Route path='ml-mb-problem-generator' element={<Post_ml_mbgenerator/>} />
            <Route path='ml-boosting-concept' element={<Post_ml_boosting_concept/>} />
            <Route path='ml-boosting-xgboost' element={<Post_ml_boosting_xgboost/>} />

            <Route path='ds-paper-review' element={<DSPaperNotes/>} />
            <Route path='ds-stat-notes' element={<DSStatNotes/>} />
            <Route path='ds-paper-notes-clv-markov-chain' element={<Post_ds_231124_clvmc/>} />
            <Route path='ds-paper-notes-t-test' element={<Post_ds_240311_ttest/>} />

          </Routes>
        </Router>
      <Footer
        description="Designed by Lucien Lo"
      />
      </Container>
  )
  // }
}
