import Title from './Title';
import FeaturedCard from './FeaturedCard';
import Grid from '@mui/material/Grid';

export default function BlockPage(props) {

  const titleInfo = props.title;
  const cards = props.cards;
 
  return (
    <main>
      <Title post={titleInfo} />
      <Grid container spacing={2}>
        {cards.map((card) => (
          <Grid item xs={12} sm={6} md={4}>
            <FeaturedCard post={card} />
          </Grid>
        ))}
      </Grid>
    </main>
  )
}

