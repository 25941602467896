import BlockPage from './BlockPage'

import mountain_001 from '../image/mountain-001.jpeg';
import mountain_002 from '../image/mountain-002.jpeg';
import mountain_003 from '../image/mountain-003.jpeg';
import mountain_004 from '../image/mountain-004.jpeg';

const title = {
  title: 'Boosting in machine learning',
  description: "",
  image: mountain_002,
};

const conceptCard = {
  title: 'Concepts of boosting in machine learning',
  description: "My notes on various boosting algorithms in machine learning",
  url: "/ml-boosting-concept",
  image: mountain_003,
};

const xgboostCard = {
  title: 'XGBoost in python',
  description: "My notes on XGBoost package in python",
  url: "/ml-boosting-xgboost",
  image: mountain_004,
};

const cards = [
    conceptCard,
    xgboostCard,
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
