import BlockPage from './BlockPage'

import norway_001 from '../image/norway-001.jpeg';
import mountain_001 from '../image/mountain-001.jpeg';
import mountain_002 from '../image/mountain-002.jpeg';

const title = {
  title: 'Data Science',
  description: "",
  image: norway_001,
};

const dsPaperCard = {
  title: 'Data science paper review',
  description: "My written notes on selected lists of data science papers.",
  url: "/ds-paper-review",
  image: mountain_001,
};

const dsStatCard = {
  title: 'Data science statistics notes',
  description: "My notes on useful statistics concepts in data science",
  url: "/ds-stat-notes",
  image: mountain_002,
};

const cards = [
  dsPaperCard,
  //dsStatCard,
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
