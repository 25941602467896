import BlockPage from './BlockPage'

import rock_001 from '../image/rock-001.jpeg';

const title = {
  title: 'Rock Climbing',
  description: "",
  image: rock_001,
};

const cards = [
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
