import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import argentiere_image from '../image/argentiere-north-face.jpeg';

export const personalData = {
  name: "Kin Ho (Lucien) Lo",
  descriptions:[
    "I am a data scientist with 5+ years of experience in application of machine learning algorithms, mathematical statistical methods to scientific data.",
    "I believe in cross-disciplinary researches and projects. I am always excited to apply my expertise to academic and industry problems.",
    "I am currently working in Booking.com. Previously I was a particle physicist working at CERN, one of the largest scientific laboratory in the world.",
  ],
  email: "khl.lucien@gmail.com",
  image: argentiere_image,
  imageText: "My picture",
  profilePicture: './static/profilepicture.jpg',
  title: 'About me',
};
