import BlockPage from './BlockPage'

import norway_001 from '../image/norway-001.jpeg';
import rock_001 from '../image/rock-001.jpeg';

const title = {
  title: 'Portfolio',
  description: "",
  image: norway_001,
};

const mbCard = {
  title: 'Moonboard problem generator',
  description: "An end-to-end Moonboard problem generator built by me, powered by machine learning models. This app is deployed with streamlit and AWS Lambda.",
  url: "/ml-mb-problem-generator",
  image: rock_001,
};

const cards = [
  mbCard,
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
