import * as React from "react";
import Container from '@mui/material/Container';
import ReactMarkdown,{RuleType} from 'markdown-to-jsx';
import TeX from '@matejmazur/react-katex';
import Title from './Title';
import 'katex/dist/katex.min.css';

export default function MarkdownPost(props) {
  
  const title = props.title;
  const markdown = props.markdown;

  return (
    <div className="post">
      <Title post={title} />
      <Container>
        <ReactMarkdown
          children={markdown}
          options={{
            renderRule(next, node, renderChildren, state) {
              if ((node.type === RuleType.codeBlock || node.type == RuleType.codeInline) && node.lang === 'latex') {
                return (
                  <TeX as="div" key={state.key}>{String.raw`${node.text}`}</TeX>
                )
              }
              return next()
            },
          }}
        />
      </Container>
    </div>
  );
}
