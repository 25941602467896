import * as React from "react";
import ReactMarkdown from "markdown-to-jsx";
import post from "./blog-post-2020-04-12-fast-ml.md";

export default function MarkdownPost() {
  let [readable, setReadable] = React.useState({ md: "" });

  React.useEffect(() => {
    fetch(post)
      .then((res) => res.text())
      .then((md) => {
        setReadable({ md });
      });
  }, []);

  return (
    <div className="post">
      <ReactMarkdown children={readable.md} />
    </div>
  );
}

