import * as React from "react";

import MarkdownPost from '../MarkdownPost.js';

import mountain_002 from '../../image/mountain-002.jpeg';
import post from "./blog-post-2023-11-24-clv-markov-chain.md";

const title = {
  title: 'Data Science Paper Notes',
  description: "",
  image: mountain_002,
};

export default function Post() {
  let [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    fetch(post)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return <MarkdownPost title={title} markdown={markdown} />; 
}

