import BlockPage from './BlockPage'

import universe_001 from '../image/universe-001.jpeg';

const title = {
  title: 'Physics',
  description: "",
  image: universe_001,
};

const cards = [
];

export default function Page() {
  return <BlockPage title={title} cards={cards} />;
}
