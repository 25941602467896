import BlockPage from './BlockPage'

import mountain_001 from '../image/mountain-001.jpeg';
import mountain_002 from '../image/mountain-002.jpeg';
import mountain_003 from '../image/mountain-003.jpeg';
import mountain_004 from '../image/mountain-004.jpeg';

const title = {
  title: 'Data science statistics notes',
  description: "",
  image: mountain_002,
};

const cards = [
];

export default function DSPage() {
  return <BlockPage title={title} cards={cards} />;
}
