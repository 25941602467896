import Title from './Title';
import FeaturedCard from './FeaturedCard';
import Grid from '@mui/material/Grid';

import gas_001 from '../image/gas-001.jpeg';

const titleInfo = {
  title: 'Artificial Intelligence',
  description: "",
  image: gas_001,
};

const featuredCard1 = {
  title: 'In-depth guide to fine tuning LLM',
  description: "My in-depth tutorial to fine tune LLM",
  url: "/guide-to-fine-tune-llm",
};

const featuredCards = [
  //featuredCard1,
];

export default function AIPage() {
  return (
    <main>
      <Title post={titleInfo} />
      <Grid container spacing={2}>
        {featuredCards.map((card) => (
          <Grid item xs={12} sm={6} md={4}>
            <FeaturedCard post={card} />
          </Grid>
        ))}
      </Grid>
    </main>
  )
}

